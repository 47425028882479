import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import {
  BRIGADES_COVERAGE_FILL_LAYER_ID,
  BRIGADES_COVERAGE_LAYER_ID,
  BRIGADES_COVERAGE_SOURCE_ID,
  BRIGADES_COVERAGE_TEXT_LAYER_ID,
  SEVERITY_COLOR_SCALE,
} from "./constants";

interface UseBrigadesCoverageMapLayerParams {
  opacity?: number;
}

const useBrigadesCoverageMapLayer = ({
  opacity = 1,
}: UseBrigadesCoverageMapLayerParams) => {
  const map = useMapContext();

  useEffect(() => {
    map.addSource(BRIGADES_COVERAGE_SOURCE_ID, {
      type: "geojson",
      promoteId: "brigadeId",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: BRIGADES_COVERAGE_FILL_LAYER_ID,
        type: "fill",
        source: BRIGADES_COVERAGE_SOURCE_ID,
        paint: {
          "fill-color": [
            "match",
            ["get", "coverageImpactSeverity"],
            ...SEVERITY_COLOR_SCALE,
          ],
        },
      },
      MapLevel.FS_BACKGROUND,
    );

    map.addLayer(
      {
        id: BRIGADES_COVERAGE_LAYER_ID,
        type: "line",
        source: BRIGADES_COVERAGE_SOURCE_ID,
        paint: {
          "line-color": "#393636",
          "line-width": 1,
        },
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer(
      {
        id: BRIGADES_COVERAGE_TEXT_LAYER_ID,
        type: "symbol",
        source: BRIGADES_COVERAGE_SOURCE_ID,
        layout: {
          "text-field": ["get", "brigadeName"],
          "symbol-placement": "point",
        },
        minzoom: 9,
      },
      MapLevel.SYMBOLS,
    );

    return () => {
      if (map.getLayer(BRIGADES_COVERAGE_FILL_LAYER_ID)) {
        map.removeLayer(BRIGADES_COVERAGE_FILL_LAYER_ID);
      }
      if (map.getLayer(BRIGADES_COVERAGE_LAYER_ID)) {
        map.removeLayer(BRIGADES_COVERAGE_LAYER_ID);
      }
      if (map.getLayer(BRIGADES_COVERAGE_TEXT_LAYER_ID)) {
        map.removeLayer(BRIGADES_COVERAGE_TEXT_LAYER_ID);
      }
      if (map.getSource(BRIGADES_COVERAGE_SOURCE_ID)) {
        map.removeSource(BRIGADES_COVERAGE_SOURCE_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    const clampedOpacity = 0.4 * opacity;
    map.setPaintProperty(BRIGADES_COVERAGE_FILL_LAYER_ID, "fill-opacity", [
      "case",
      [
        "any",
        ["boolean", ["feature-state", InteractionStateType.HOVERED], false],
        ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
      ],
      Math.min(clampedOpacity + 0.2, 1),
      clampedOpacity,
    ]);
    map.setPaintProperty(BRIGADES_COVERAGE_LAYER_ID, "line-opacity", [
      "case",
      [
        "any",
        ["boolean", ["feature-state", InteractionStateType.HOVERED], false],
        ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
      ],
      1,
      opacity,
    ]);
    map.setPaintProperty(BRIGADES_COVERAGE_TEXT_LAYER_ID, "text-opacity", [
      "case",
      [
        "any",
        ["boolean", ["feature-state", InteractionStateType.HOVERED], false],
        ["boolean", ["feature-state", InteractionStateType.CLICKED], false],
      ],
      1,
      opacity,
    ]);
  }, [opacity, map]);
};

export default useBrigadesCoverageMapLayer;
