import { stateForestPlantationLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import StateForestPlantationLayer from "./StateForestPlantationLayer";

const StateForestPlantation = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(stateForestPlantationLayer.id)) {
    return null;
  }

  const state = getLayerState(stateForestPlantationLayer.id);

  return <StateForestPlantationLayer opacity={state?.opacity} />;
};

export default StateForestPlantation;
