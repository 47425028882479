import BrigadeCoverageSnapshotPopup from "../../popup/BrigadeCoverageSnapshotPopup/BrigadeCoverageSnapshotPopup";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  BRIGADES_COVERAGE_FILL_LAYER_ID,
  BRIGADES_COVERAGE_SOURCE_ID,
} from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import useBrigadesCoverageMapData from "./useBrigadesCoverageMapData";
import useBrigadesCoverageMapLayer from "./useBrigadesCoverageMapLayer";

interface RfsBrigadesCoverageLayerProps {
  opacity?: number;
}

const RfsBrigadesCoverageLayer = ({
  opacity,
}: RfsBrigadesCoverageLayerProps) => {
  useBrigadesCoverageMapData();
  useBrigadesCoverageMapLayer({ opacity });

  const { clickedState, hoveredState, deactivateClickState } =
    useLayerInteractions({
      getPropertiesFromFeature,
      layerId: BRIGADES_COVERAGE_FILL_LAYER_ID,
    });

  useInteractionFeatureState({
    clickedState,
    hoveredState,
    sourceId: BRIGADES_COVERAGE_SOURCE_ID,
  });

  return (
    <BrigadeCoverageSnapshotPopup
      onClose={deactivateClickState}
      state={clickedState}
    />
  );
};

export default RfsBrigadesCoverageLayer;
