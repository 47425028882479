import {
  Ellipsis,
  IconButton,
  type IconButtonVariant,
  Link,
  OpenInNewTab,
  OverflowMenu,
  showToast,
} from "@app/design-system";
import React, { type Key } from "react";

interface IncidentOverflowMenuProps {
  incidentId: string;
  name: string;
  variant: IconButtonVariant;
}

const items = [
  { key: "newTab", label: "Open in new tab", icon: OpenInNewTab },
  { key: "copy", label: "Copy link to clipboard", icon: Link },
];

const IncidentOverflowMenu = ({
  incidentId,
  name,
  variant,
}: IncidentOverflowMenuProps) => {
  const incidentUrl = `${window.location.origin}/incidents/${incidentId}`;
  const onAction = (key: Key) => {
    switch (key) {
      case "newTab": {
        window.open(incidentUrl, "_blank");
        break;
      }
      case "copy": {
        navigator.clipboard.writeText(incidentUrl).then(
          () => {
            showToast({
              title: "Copied",
              message: `"${name}" link copied to clipboard`,
              variant: "success",
              id: `${incidentId}-clipboard-success`,
            });
          },
          () => {
            showToast({
              title: "Unable to copy",
              message: `Error copying "${name}" link to clipboard`,
              variant: "error",
              id: `${incidentId}-clipboard-error`,
            });
          },
        );
        break;
      }
      default:
        break;
    }
  };

  return (
    <OverflowMenu
      items={items}
      onAction={(key: Key) => {
        onAction(key);
      }}
      renderTrigger={(props) => (
        <IconButton
          {...props}
          icon={Ellipsis}
          label="Actions"
          size="sm"
          variant={variant}
        />
      )}
    />
  );
};

export default IncidentOverflowMenu;
