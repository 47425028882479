import { aircraftLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import AircraftPositionsLayer from "./AircraftPositionsLayer";

interface AircraftPositionsProps {
  isCopView: boolean;
}

const AircraftPositions = ({ isCopView }: AircraftPositionsProps) => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(aircraftLayer.id)) {
    return null;
  }

  const state = getLayerState(aircraftLayer.id);

  return (
    <AircraftPositionsLayer isCopView={isCopView} opacity={state?.opacity} />
  );
};

export default AircraftPositions;
