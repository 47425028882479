import {
  Ellipsis,
  IconButton,
  type IconButtonSize,
  Link,
  Location,
  OverflowMenu,
  showToast,
  Visibility,
} from "@app/design-system";
import type { Key } from "react";
import QueryParams from "../../../config/queryParams";
import getExternalTweetUrl from "../../../utils/getExternalTweetUrl/getExternalTweetUrl";
import { getTweetUrl } from "../CopyLinkToTweetButton/CopyLinkToTweetButton";
import type { PostLink } from "../SocialFeed/socialFeedQuery";

const items = [
  { key: "viewOnMap", label: "View on map", icon: Location },
  { key: "copy", label: "Copy link to clipboard", icon: Link },
  { key: "viewPost", label: "View post", icon: Visibility },
];

interface TweetOverflowMenuProps {
  externalId: string;
  link: PostLink;
  size?: IconButtonSize;
  tweetId: string;
  username: string;
}

const TweetOverflowMenu = ({
  externalId,
  link,
  size = "md",
  tweetId,
  username,
}: TweetOverflowMenuProps) => {
  const tweetIncidentUrl = `${window.location.origin}/incidents/${link.incidentId}/social?${QueryParams.TWEET_ID}=${tweetId}&${QueryParams.INCIDENT_ID}=${link.incidentId}`;
  const tweetHotspotUrl = `${window.location.origin}/social?${QueryParams.TWEET_ID}=${tweetId}`;
  const externalSocialUrl = getExternalTweetUrl({ externalId, username });

  const onAction = (key: Key) => {
    switch (key) {
      case "viewOnMap":
        if (link.linkedTo === "linked_to_incident") {
          return window.open(tweetIncidentUrl, "_blank");
        }

        return window.open(tweetHotspotUrl, "_blank");

      case "copy": {
        const url = getTweetUrl({ link, tweetId });

        navigator.clipboard.writeText(url).then(
          () => {
            showToast({
              title: "Copied",
              message: "Tweet link copied to clipboard",
              variant: "success",
              id: `${tweetId}-clipboard-success`,
            });
          },
          () => {
            showToast({
              title: "Unable to copy",
              message: "Error copying tweet link to clipboard",
              variant: "error",
              id: `${tweetId}-clipboard-error`,
            });
          },
        );
        break;
      }
      case "viewPost":
        window.open(externalSocialUrl, "_blank");
        break;
      default:
      // no action
    }
  };
  return (
    <OverflowMenu
      items={items}
      placement="bottom right"
      renderTrigger={(triggerProps) => (
        <IconButton
          icon={Ellipsis}
          label="More options"
          size={size}
          variant="ghost"
          {...triggerProps}
        />
      )}
      onAction={(key: Key) => {
        onAction(key);
      }}
    />
  );
};

export default TweetOverflowMenu;
