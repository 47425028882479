import {
  Button,
  IconButton,
  Tooltip,
  buttonReset,
  focusStyles,
  showToast,
  weakStyles,
  type ButtonProps,
  type TooltipInstance,
  type TooltipProps,
} from "@app/design-system";
import type { IconButtonProps } from "@app/design-system/src/components/IconButton/IconButton";
import { mergeProps } from "@react-aria/utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

const StyledCopyToClipboardText = styled.button`
  ${buttonReset}
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-self: start;
  ${(p) => p.theme.typography.variants.bodyDefault}
  border-radius: ${(p) => p.theme.borderRadiuses.base}px;
  text-wrap: balance;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    inset: 0 -0.25rem;
    z-index: -1;
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;
    transition: background-color
      ${(p) => `${p.theme.anim.duration.md}ms ${p.theme.anim.curve}`};
  }

  &:hover::after {
    ${weakStyles.background.hover}
  }

  &:active::after {
    ${weakStyles.background.active}
  }

  &:focus-visible {
    ${focusStyles("ring")}
  }
`;

const DEFAULT_COPY_PROMPT = "Copy to clipboard";
const SUCCESSFULLY_COPIED = "Copied!";

interface UseCopyTooltipParams {
  copyPrompt?: string;
  textToCopy: string;
}

const useCopyTooltip = ({
  copyPrompt = DEFAULT_COPY_PROMPT,
  textToCopy,
}: UseCopyTooltipParams) => {
  const [label, setLabel] = useState(copyPrompt);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const tooltipRef = useRef<TooltipInstance | null>(null);

  const onClick = useCallback(() => {
    navigator.clipboard.writeText(textToCopy).then(
      () => {
        setLabel(SUCCESSFULLY_COPIED);

        // Clear any existing timeout
        if (timeoutId.current) {
          clearTimeout(timeoutId.current);
        }

        // Set a new timeout
        timeoutId.current = setTimeout(() => {
          setLabel(copyPrompt);
          timeoutId.current = null;
        }, 2000);

        tooltipRef.current?.show();
      },
      () => {
        showToast({
          title: "Unable to copy",
          variant: "error",
          id: `${textToCopy}-clipboard-error`,
        });
      },
    );
  }, [copyPrompt, textToCopy]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return {
    buttonProps: { onClick },
    tooltipProps: {
      delay: true,
      hideOnClick: false,
      leaveDelay: label === SUCCESSFULLY_COPIED,
      message: label,
      tooltipRef: (instance: TooltipInstance) => {
        tooltipRef.current = instance;
      },
    } satisfies Omit<TooltipProps, "children">,
  } as const;
};

interface CopyableTextProps {
  children?: React.ReactNode;
  copyPrompt?: string;
  textToCopy: string;
}

const CopyableText = ({
  children,
  copyPrompt = DEFAULT_COPY_PROMPT,
  textToCopy,
}: CopyableTextProps) => {
  const { buttonProps, tooltipProps } = useCopyTooltip({
    copyPrompt,
    textToCopy,
  });

  return (
    <Tooltip {...tooltipProps}>
      <StyledCopyToClipboardText {...buttonProps}>
        {children}
      </StyledCopyToClipboardText>
    </Tooltip>
  );
};

export default CopyableText;

type CopyToClipboardButtonProps = ButtonProps & UseCopyTooltipParams;

export const CopyToClipboardButton = ({
  children,
  copyPrompt = "",
  textToCopy,
  ...props
}: CopyToClipboardButtonProps) => {
  const { buttonProps, tooltipProps } = useCopyTooltip({
    copyPrompt,
    textToCopy,
  });

  return (
    <Tooltip {...tooltipProps}>
      <Button {...mergeProps(props, buttonProps)}>{children}</Button>
    </Tooltip>
  );
};

type CopyToClipboardIconButtonProps = IconButtonProps & UseCopyTooltipParams;

export const CopyToClipboardIconButton = ({
  children,
  copyPrompt,
  textToCopy,
  ...props
}: CopyToClipboardIconButtonProps) => {
  const { buttonProps, tooltipProps } = useCopyTooltip({
    copyPrompt,
    textToCopy,
  });

  return (
    <Tooltip {...tooltipProps}>
      <IconButton noTooltip {...mergeProps(props, buttonProps)}>
        {children}
      </IconButton>
    </Tooltip>
  );
};
