import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import catchAbortError from "../../../utils/catchAbortError/catchAbortError";
import useMapContext from "../Map/useMapContext";
import { loadImage } from "../utils/loadImage";
import {
  AIRCRAFT,
  AIRCRAFT_POSITIONS_LAYER_ID,
  AIRCRAFT_POSITIONS_SOURCE_ID,
} from "./constants";

interface UseAircraftPositionsMapLayerProps {
  opacity?: number;
}

const useAircraftPositionsMapLayer = ({
  opacity = 1,
}: UseAircraftPositionsMapLayerProps) => {
  const map = useMapContext();

  useEffect(() => {
    const controller = new AbortController();

    map.addSource(AIRCRAFT_POSITIONS_SOURCE_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    Promise.all(
      Object.entries(AIRCRAFT).map(([imageId, src]) =>
        loadImage({ imageId, map, src, signal: controller.signal }),
      ),
    ).then(() => {
      map.addLayer(
        {
          id: AIRCRAFT_POSITIONS_LAYER_ID,
          type: "symbol",
          source: AIRCRAFT_POSITIONS_SOURCE_ID,
          layout: {
            "icon-image": [
              "coalesce",
              ["image", ["get", "categoryStatus"]],
              ["image", "not-known"],
            ],
            "icon-allow-overlap": true,
            "icon-size": 0.3,
          },
        },
        MapLevel.FS_SYMBOLS,
      );
    }, catchAbortError);

    return () => {
      controller.abort();
      if (map.getLayer(AIRCRAFT_POSITIONS_LAYER_ID)) {
        map.removeLayer(AIRCRAFT_POSITIONS_LAYER_ID);
      }
      if (map.getSource(AIRCRAFT_POSITIONS_SOURCE_ID)) {
        map.removeSource(AIRCRAFT_POSITIONS_SOURCE_ID);
      }
    };
  }, [map]);

  useEffect(() => {
    map.setPaintProperty(AIRCRAFT_POSITIONS_LAYER_ID, "icon-opacity", opacity);
  }, [map, opacity]);
};

export default useAircraftPositionsMapLayer;
