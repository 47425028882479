import { rtaIncidents } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RtaIncidentsLayer from "./RtaIncidentsLayer";

const RtaIncidents = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(rtaIncidents.id)) {
    return null;
  }

  const state = getLayerState(rtaIncidents.id);

  return <RtaIncidentsLayer opacity={state?.opacity} />;
};

export default RtaIncidents;
