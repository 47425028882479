import { useUpdatingRef } from "@kablamo/kerosene-ui";
import Tippy from "@tippyjs/react";
import { useCallback } from "react";
import styled, { useTheme } from "styled-components";
import type { Instance, LifecycleHooks, Placement } from "tippy.js";

export type { Instance as TooltipInstance };

const StyledTippy = styled(Tippy)`
  border-radius: ${(p) => p.theme.borderRadiuses.lg}px;
  max-width: 18rem !important;
  transition-timing-function: ${(p) => p.theme.anim.curve};

  &[data-state="hidden"] {
    transform: scale(0.98);
  }

  .tippy-content {
    ${(p) => p.theme.typography.variants.footnote}
    padding: 0.375rem 0.5rem;
    background-color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
    color: ${(p) => p.theme.colors.neutrals.textInverse};
    border-radius: ${(p) => p.theme.borderRadiuses.base}px;
    box-shadow: ${(p) => p.theme.boxShadows.sm};
  }

  .tippy-arrow {
    color: ${(p) => p.theme.colors.neutrals.backgroundInverse};
  }

  &.tippy-box[data-placement^="top"] > .tippy-arrow:before {
    bottom: -5px;
  }

  &.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
    top: -5px;
  }
`;

export interface TooltipProps {
  children: JSX.Element | undefined;
  delay?: boolean;
  hideOnClick?: boolean;
  interactive?: boolean;
  leaveDelay?: boolean;
  message: React.ReactNode;
  offset?: [number, number];
  placement?: Placement;
  trigger?: string;
  tooltipRef?: (instance: Instance) => void;
  visible?: boolean;
}

export const Tooltip = ({
  children,
  delay,
  hideOnClick,
  interactive,
  leaveDelay,
  message,
  offset,
  placement = "top",
  tooltipRef: _tooltipRef,
  visible,
}: TooltipProps) => {
  const theme = useTheme();

  const tooltipRef = useUpdatingRef(_tooltipRef);

  /**
   * Apply a data attribute so that we can style any element that has a tooltip,
   * such as in the `weak` variant of the `Text` component.
   */
  const onCreate = useCallback<LifecycleHooks["onCreate"]>(
    (instance) => {
      instance.reference.setAttribute("data-has-tooltip", "true");
      tooltipRef.current?.(instance);
    },
    [tooltipRef],
  );

  if (!message) return <>{children}</>;

  return (
    <StyledTippy
      content={message}
      duration={theme.anim.duration.sm}
      hideOnClick={hideOnClick}
      offset={offset}
      onCreate={onCreate}
      placement={placement}
      visible={visible}
      {...((delay || leaveDelay) && {
        delay: [
          delay ? theme.anim.duration.lg : 0,
          leaveDelay ? theme.anim.duration.lg : 0,
        ],
      })}
      {...(delay && {
        // Only require long pressing on mobile for tooltips with a delay (i.e.
        // icon buttons and other interactive elements).
        touch: ["hold", theme.anim.duration.lg],
      })}
      // Cannot specify `trigger` if `visible` is being controlled
      trigger={typeof visible === "boolean" ? undefined : "mouseenter"}
      interactive={interactive}
    >
      {children}
    </StyledTippy>
  );
};
