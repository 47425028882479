import type { GetPropertiesFromFeatureFn } from "../MapInteractions/MapInteractionsProvider";
import type { FeatureInteractionProperties } from "../MapInteractions/types";
import { isMultiPolygonFeature, isPolygonFeature } from "../types";

interface RfsBrigadesInteractionProperties
  extends FeatureInteractionProperties {
  objectId: string;
  brigadeId: string;
  brigadeName: string;
}

export const getPropertiesFromFeature: GetPropertiesFromFeatureFn<
  RfsBrigadesInteractionProperties
> = (feature, event) => {
  if (!isMultiPolygonFeature(feature) && !isPolygonFeature(feature)) {
    return null;
  }

  const objectId = `${feature.properties.OBJECTID}`;
  const { lngLat } = event;

  return {
    featureId: objectId,
    lngLat,
    objectId,
    brigadeId: feature.properties.BrigadeID,
    brigadeName: feature.properties.BrigadeName,
  };
};
