import { useEffect } from "react";
import { MapLevel } from "../../../config/layers/layers";
import buildMapboxBBoxQuery from "../../../utils/buildMapboxBBoxQuery";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import useMapContext from "../Map/useMapContext";

const query = buildMapboxBBoxQuery({
  dpi: "96",
  format: "png8",
  layers: `show:5`,
  transparent: "true",
  bboxSR: "3857",
  imageSR: "3857",
  size: "512,512",
  f: "image",
});

const StateBorderLayer = () => {
  const layerId = "stateBorder";
  const map = useMapContext();
  const tileUrl = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/export`;

  useEffect(() => {
    const controller = new AbortController();
    controller.signal.addEventListener("abort", () => {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }

      if (map.getSource(layerId)) {
        map.removeSource(layerId);
      }
    });

    map.addSource(layerId, {
      type: "raster",
      tileSize: 512,
      tiles: [`${tileUrl}?${query}`],
    });

    map.addLayer(
      { id: layerId, type: "raster", source: layerId },
      MapLevel.BACKGROUND,
    );

    return () => controller.abort();
  }, [map, tileUrl]);

  return null;
};

export default StateBorderLayer;
