import { rfsBrigades } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RfsBrigadesLayer from "./RfsBrigadesLayer";

const RfsBrigades = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(rfsBrigades.id)) {
    return null;
  }

  const state = getLayerState(rfsBrigades.id);

  return <RfsBrigadesLayer opacity={state?.opacity} />;
};

export default RfsBrigades;
