import AircraftPositionsPopup from "../../popup/AircraftPositionsPopup/AircraftPositionsPopup";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import { AIRCRAFT_POSITIONS_LAYER_ID } from "./constants";
import { getPropertiesFromFeature } from "./interactions";
import useAircraftPositionsMapData from "./useAircraftPositionsMapData";
import useAircraftPositionsMapLayer from "./useAircraftPositionsMapLayer";

interface AircraftPositionsLayerProps {
  isCopView: boolean;
  opacity?: number;
}

const AircraftPositionsLayer = ({
  isCopView,
  opacity,
}: AircraftPositionsLayerProps) => {
  useAircraftPositionsMapLayer({ opacity });
  useAircraftPositionsMapData();

  const interactionState = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: AIRCRAFT_POSITIONS_LAYER_ID,
  });

  const {
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = interactionState;

  return (
    <>
      <AircraftPositionsPopup
        isCopView={isCopView}
        onClose={deactivateHoverState}
        state={hoveredState}
        type="hover"
      />
      <AircraftPositionsPopup
        isCopView={isCopView}
        onClose={deactivateClickState}
        state={clickedState}
        type="click"
      />
    </>
  );
};

export default AircraftPositionsLayer;
