import { useRouter } from "next/router";
import { useEffect } from "react";
import getActiveLayersFromQuery from "../../../config/getActiveLayersFromQuery";
import { rfsBrigadesCoverage } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import RfsBrigadesCoverageLayer from "./RfsBrigadesCoverageLayer";

const RfsBrigadesCoverage = () => {
  const { query } = useRouter();
  const { getLayerState, isLayerActive, activateLayer } =
    useActiveLayersContext();

  useEffect(() => {
    // TODO: Refactor this so that we have first-class support for
    // pre-activating layers from query params rather than needing to be
    // colocated with the layer component.
    const activeLayers = getActiveLayersFromQuery(query);

    if (activeLayers?.includes(rfsBrigadesCoverage.id)) {
      activateLayer({
        id: rfsBrigadesCoverage.id,
        source: "layers-drawer",
      });
    }
  }, [query, activateLayer]);

  if (isLayerActive(rfsBrigadesCoverage.id)) {
    return (
      <RfsBrigadesCoverageLayer
        opacity={getLayerState(rfsBrigadesCoverage.id)?.opacity}
      />
    );
  }

  return null;
};

export default RfsBrigadesCoverage;
