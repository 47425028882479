import { neighbourhoodSaferPlacesLayer } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import NeighbourhoodSaferPlacesLayer from "./NeighbourhoodSaferPlacesLayer";

const NeighbourhoodSaferPlaces = () => {
  const { getLayerState, isLayerActive } = useActiveLayersContext();

  if (!isLayerActive(neighbourhoodSaferPlacesLayer.id)) {
    return null;
  }

  const state = getLayerState(neighbourhoodSaferPlacesLayer.id);

  return <NeighbourhoodSaferPlacesLayer opacity={state?.opacity} />;
};

export default NeighbourhoodSaferPlaces;
