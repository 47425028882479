import { useTheme } from "@app/design-system";
import { useEffect } from "react";
import { MapLevel, rfsBrigades } from "../../../config/layers/layers";
import { EMDASH } from "../../../lib/strings";
import getMapServerProxyBasepath from "../../../utils/getMapServerProxyBasepath";
import PreviewSpatialPopup from "../../popup/PreviewSpatialPopup/PreviewSpatialPopup";
import useMapContext from "../Map/useMapContext";
import { InteractionStateType } from "../MapInteractions/types";
import useInteractionFeatureState from "../MapInteractions/useInteractionFeatureState";
import useLayerInteractions from "../MapInteractions/useLayerInteractions";
import {
  type QueryOptions,
  useMapServerQueryData,
} from "../hooks/useMapServerQueryData/useMapServerQueryData";
import { isGeoJsonSource } from "../types";
import { isMaplibre } from "../utils/isMaplibre";
import { getPropertiesFromFeature } from "./interactions";

const RFS_BRIGADES_ID = rfsBrigades.id;
const RFS_BRIGADES_LINE_ID = `${RFS_BRIGADES_ID}-line`;
const RFS_BRIGADES_TEXT_ID = `${RFS_BRIGADES_ID}-text`;
const RFS_BRIGADES_ACTIVE_LINE_ID = `${RFS_BRIGADES_ID}-line-active`;

const RFS_BRIGADES_QUERY_URL = `${getMapServerProxyBasepath()}/arcgis/rest/services/Reference/Athena/MapServer/7/query`;
const QUERY_OPTIONS: QueryOptions = {
  queryParams: {
    outFields: ["OBJECTID", "BrigadeID", "BrigadeName"].join(","),
  },
};

interface RfsBrigadesLayerProps {
  opacity?: number;
}

const RfsBrigadesLayer = ({ opacity = 1 }: RfsBrigadesLayerProps) => {
  const map = useMapContext();
  const { data } = useMapServerQueryData(RFS_BRIGADES_QUERY_URL, QUERY_OPTIONS);
  const theme = useTheme();

  useEffect(() => {
    map.addSource(RFS_BRIGADES_ID, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer(
      {
        id: RFS_BRIGADES_ID,
        type: "fill",
        source: RFS_BRIGADES_ID,
        paint: { "fill-opacity": 0.01 },
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer(
      {
        id: RFS_BRIGADES_LINE_ID,
        type: "line",
        source: RFS_BRIGADES_ID,
        paint: {
          "line-color": "#393636",
          "line-width": 2,
        },
      },
      MapLevel.LINES,
    );

    map.addLayer(
      {
        id: RFS_BRIGADES_TEXT_ID,
        type: "symbol",
        source: RFS_BRIGADES_ID,
        layout: {
          "text-field": ["get", "BrigadeName"],
          "symbol-placement": "point",
          "text-font": isMaplibre(map) ? ["Noto Sans Bold"] : ["DIN Pro Bold"],
          "text-size": 16,
        },
        paint: {
          "text-color": theme.colors.neutrals.text,
          "text-halo-color": "#FFF",
          "text-halo-width": 1,
          "text-halo-blur": 0,
        },
        minzoom: 11,
      },
      MapLevel.BACKGROUND,
    );

    map.addLayer(
      {
        id: RFS_BRIGADES_ACTIVE_LINE_ID,
        type: "line",
        source: RFS_BRIGADES_ID,
        paint: {
          "line-color": theme.colors.mapping.redMap,
          "line-width": 2,
          "line-opacity": [
            "case",
            [
              "any",
              [
                "boolean",
                ["feature-state", InteractionStateType.HOVERED],
                false,
              ],
              [
                "boolean",
                ["feature-state", InteractionStateType.CLICKED],
                false,
              ],
            ],
            1,
            0,
          ],
        },
      },
      MapLevel.LINES,
    );

    return () => {
      if (map.getLayer(RFS_BRIGADES_ID)) {
        map.removeLayer(RFS_BRIGADES_ID);
      }
      if (map.getLayer(RFS_BRIGADES_TEXT_ID)) {
        map.removeLayer(RFS_BRIGADES_TEXT_ID);
      }
      if (map.getLayer(RFS_BRIGADES_LINE_ID)) {
        map.removeLayer(RFS_BRIGADES_LINE_ID);
      }
      if (map.getLayer(RFS_BRIGADES_ACTIVE_LINE_ID)) {
        map.removeLayer(RFS_BRIGADES_ACTIVE_LINE_ID);
      }
      if (map.getSource(RFS_BRIGADES_ID)) {
        map.removeSource(RFS_BRIGADES_ID);
      }
    };
  }, [map, theme.colors.mapping.redMap, theme.colors.neutrals.text]);

  useEffect(() => {
    const source = map?.getSource(RFS_BRIGADES_ID);
    if (isGeoJsonSource(source) && data) {
      source.setData(data);
    }
  }, [data, map]);

  useEffect(() => {
    map.setPaintProperty(RFS_BRIGADES_LINE_ID, "line-opacity", opacity);
    map.setPaintProperty(RFS_BRIGADES_TEXT_ID, "text-opacity", opacity);
  }, [map, opacity]);

  const {
    clickedState,
    deactivateClickState,
    deactivateHoverState,
    hoveredState,
  } = useLayerInteractions({
    getPropertiesFromFeature,
    layerId: RFS_BRIGADES_ID,
  });

  useInteractionFeatureState({
    clickedState,
    hoveredState,
    sourceId: RFS_BRIGADES_ID,
  });

  return (
    <>
      <PreviewSpatialPopup onClose={deactivateHoverState} state={hoveredState}>
        Brigade: {hoveredState.properties?.brigadeName || EMDASH}
      </PreviewSpatialPopup>
      <PreviewSpatialPopup
        onClose={deactivateClickState}
        state={clickedState}
        type="click"
      >
        Brigade: {clickedState.properties?.brigadeName || EMDASH}
      </PreviewSpatialPopup>
    </>
  );
};

export default RfsBrigadesLayer;
