import {
  Button,
  FieldGrid,
  SkeletonButton,
  StatusMessage,
  Text,
  Tooltip,
} from "@app/design-system";
import getConfig from "next/config";
import Link from "next/link";
import styled from "styled-components";
import { useGetResourcesExternalExternalId } from "../../../../.rest-hooks/resources";
import useQueryAsyncValue from "../../../hooks/useQueryAsyncValue";
import type { AVLInteractionProperties } from "../../map/AVLPositions/interactions";
import type { AVLType } from "../../map/AVLPositions/utils";
import AsyncView from "../../util/AsyncView/AsyncView";
import AVLPopupViewResourceDetailButton from "./AVLPopupViewResourceDetailButton";

const { publicRuntimeConfig } = getConfig();

const StyledFooter = styled.div`
  display: grid;
  gap: 0.5rem;
  padding-top: 0.75rem;
`;

const StyledMessage = styled.div`
  width: fit-content;
`;

export interface AVLPopupDetailProps {
  isCopView: boolean;
  properties: AVLInteractionProperties;
  type: AVLType;
}

const formatValue = (value: string | number | undefined): string => {
  if (typeof value === "number") {
    return String(value);
  }

  return value || "—";
};

const AVLPopupDetail = ({
  isCopView,
  properties,
  type,
}: AVLPopupDetailProps) => {
  const { COP_ENABLED } = publicRuntimeConfig;

  const resourceQuery = useGetResourcesExternalExternalId(
    properties.data.BfNumber,
    {
      query: {
        enabled: !!properties.data.BfNumber,
        retry: false,
        select: (data) => data.data.data,
      },
    },
  );

  const resourceResult = useQueryAsyncValue({ query: resourceQuery });

  const fieldItems = COP_ENABLED ? (
    <>
      {type === "appliance" && (
        <FieldGrid.Item label="BF0:">
          {formatValue(properties.data.BfNumber)}
        </FieldGrid.Item>
      )}
      <FieldGrid.Item label="Radio ID:">
        {formatValue(properties.data.RadioId)}
      </FieldGrid.Item>
      <FieldGrid.Item label="Talk group ID:">
        {formatValue(properties.data.TalkGroupId)}
      </FieldGrid.Item>
      <FieldGrid.Item label="Report age:">
        {formatValue(properties.data.ReportAge)}
      </FieldGrid.Item>
    </>
  ) : (
    <>
      <FieldGrid.Item label="Report age:">
        {formatValue(properties.data.ReportAge)}
      </FieldGrid.Item>
      <FieldGrid.Item label="Radio ID:">
        {formatValue(properties.data.RadioId)}
      </FieldGrid.Item>
    </>
  );

  return (
    <>
      <Text size="subtitleMd">{properties.data.CallSign}</Text>
      <FieldGrid>{fieldItems}</FieldGrid>
      {type === "appliance" && (
        <StyledFooter>
          {properties.data.BfNumber ? (
            <AsyncView
              {...resourceResult}
              errorState={
                <StatusMessage variant="error">
                  Unable to load resource
                </StatusMessage>
              }
              loadingState={<SkeletonButton size="sm" />}
            >
              {(resource) => (
                <AVLPopupViewResourceDetailButton resource={resource} />
              )}
            </AsyncView>
          ) : (
            <Tooltip message="This resource is missing a BfNumber">
              <StyledMessage>
                <StatusMessage variant="error">
                  Unable to identify resource
                </StatusMessage>
              </StyledMessage>
            </Tooltip>
          )}
          {!isCopView && (
            <Link
              as="/resources"
              passHref
              href={{
                pathname: "/resources",
              }}
            >
              <Button fullWidth size="sm" variant="secondary">
                View all resources
              </Button>
            </Link>
          )}
        </StyledFooter>
      )}
    </>
  );
};

export default AVLPopupDetail;
