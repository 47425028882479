import { rfsBrigadesCoverage } from "../../../config/layers/layers";

export const BRIGADES_COVERAGE_SOURCE_ID = rfsBrigadesCoverage.id;
export const BRIGADES_COVERAGE_LAYER_ID = BRIGADES_COVERAGE_SOURCE_ID;
export const BRIGADES_COVERAGE_FILL_LAYER_ID = `${BRIGADES_COVERAGE_SOURCE_ID}-fill`;
export const BRIGADES_COVERAGE_TEXT_LAYER_ID = `${BRIGADES_COVERAGE_SOURCE_ID}-text`;

export const SEVERITY_COLOR_SCALE = [
  "assessed",
  "#5d5d5d",
  "severe",
  "#EA1D2C",
  "moderate",
  "#F58C1D",
  "no-impact",
  "#0BA350",
  "unknown",
  "transparent",
  "transparent",
] as const;
